<template>
    <div class="mobile-demo">
        hihi from mobile
    </div>
</template>

<script>


export default {
    name: "MobileDemo",
    data() {
        return {
        };
    },
};
</script>